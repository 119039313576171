export type caseState = 'open' | 'closed';
export type caseType = 'customer_opinion';
type caseSource = 'SL' | 'TiB' | 'ÖT' | 'ST';
type caseSentTo = 'Ecase' | 'THR' | 'Other';
type caseContent = {
  type: 'paragraph' | 'heading' | 'text' | 'bulletList' | 'listItem';
  attrs?: Record<string, string | number>;
  content?: caseContent[];
  text?: string;
};

type commentContent = {
  createdAt: Date;
  visibility: string;
  id: string;
  author: commentAuthor;
  content: comment[];
};

type commentAuthor = {
  email: string;
  type: string;
  name: string;
};

type comment = {
  text: string;
  type: string;
  content: comment[];
};

export interface CaseModel {
  id: string;
  state: caseState;
  relatedTo?: Partial<CaseModel>[];
  summary: string;
  description?: caseContent[];
  comments?: commentContent[];
  depo: string;
  source?: caseSource;
  referenceId: string;
  caseType: caseType;
  isMissingInformation?: boolean;
  isNothingWrong?: boolean;
  requiredCustomerFeedbackAction?: boolean;
  fromStation?: string;
  toStation?: string;
  line?: string;
  vehicleNumber?: string;
  eventDate?: Date;
  reportedAt?: Date;
  createdAt: Date;
  category1?: string;
  category2?: string;
  category3?: string;
  sentTo?: caseSentTo[];
  staffMemberId?: string;
  role?: string;
  staffMemberRole?: string;
  customerEmail?: string;
  customerPhone?: string;
  customerName?: string;
  resolvedAt?: string;
}
