import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CaseService } from '@shared/services/case.service';
import { category1, category2 } from 'src/app/model';
import {
  ButtonSizeV2,
  ButtonType,
  ButtonTypeV2,
  InputType,
  LabelType,
} from '@vr/vr-storybook-lib';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'cm-create-case',
  templateUrl: './create-case.component.html',
  styleUrls: ['./create-case.component.scss'],
})
export class CreateCaseComponent {
  labelType = LabelType;
  buttonType = ButtonType;
  ButtonTypeV2 = ButtonTypeV2;
  ButtonSizeV2 = ButtonSizeV2;
  inputType = InputType;
  cat1 = category1; //TODO - temporarily attached to the front
  cat2 = category2; //TODO - temporarily attached to the front

  public caseType = new FormControl<string>('', [Validators.required]);

  public basic = new FormGroup({
    summary: new FormControl<string>('', [Validators.required]),
    referenceId: new FormControl<string>('', [Validators.required]),
    caseType: new FormControl<string>('', [Validators.required]),
    depo: new FormControl<string>('', [Validators.required]),
    source: new FormControl<string>('', [Validators.required]),
  });

  public description = new FormGroup({
    eventDate: new FormControl(''),
    reportedAt: new FormControl(''),
    description: new FormControl(''),
    isMissingInformation: new FormControl<boolean>(false),
    isNothingWrong: new FormControl<boolean>(false),
  });

  public details = new FormGroup({
    fromStation: new FormControl<string>(''),
    toStation: new FormControl<string>(''),
    line: new FormControl<string>(''),
    vehicleNumber: new FormControl<string>(''),
    category1: new FormControl<string>(''),
    category2: new FormControl<string>(''),
    category3: new FormControl<string>(''),
    sentTo: new FormControl<string[]>(['Other']),
    staffMemberId: new FormControl<string>(''),
    role: new FormControl<string>(''),
  });

  public customerInfo = new FormGroup({
    customerName: new FormControl<string>(''),
    customerEmail: new FormControl<string>(''),
    customerPhone: new FormControl<string>(''),
    requiredCustomerFeedbackAction: new FormControl<boolean>(false),
  });

  changeEventDate(event: { value: any }) {
    this.description
      .get('eventDate')
      ?.patchValue(
        this.datePipe.transform(event.value, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'")
      );
  }

  changeReportedAt(event: { value: any }) {
    this.description
      .get('reportedAt')
      ?.patchValue(
        this.datePipe.transform(event.value, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'")
      );
  }

  ngOnInit(): void {}

  saveCase(): void {
    this.basic.markAllAsTouched();
    this.basic.updateValueAndValidity();
    this.description.markAllAsTouched();
    this.description.updateValueAndValidity();
    this.details.markAllAsTouched();
    this.details.updateValueAndValidity();

    if (
      this.description.invalid ||
      this.basic.invalid ||
      this.details.invalid ||
      this.customerInfo.invalid
    ) {
      return;
    }

    const { eventDate, reportedAt, ...descriptionWithoutEventDate } =
      this.description.value;

    const { sentTo, ...detailsData } = this.details.value;

    const payload = {
      ...this.basic.value,
      ...descriptionWithoutEventDate,
      ...detailsData,
      ...this.customerInfo.value,
      ...(eventDate && {
        eventDate: eventDate,
      }),
      ...(reportedAt && {
        reportedAt: reportedAt,
      }),
      sentTo: this.details.value.sentTo ?? ['Other'],
    };
    this.caseService.createCase(payload).subscribe({
      next: resp => {
        this.router.navigate(['/case', resp]);
      },
    });
  }

  missingInformationChange({ target }: { target: HTMLInputElement }) {
    this.description.get('isMissingInformation')?.patchValue(target.checked);
  }
  nothingWrongChange({ target }: { target: HTMLInputElement }) {
    this.description.get('isNothingWrong')?.patchValue(target.checked);
  }
  customerFeedbackActionChange({ target }: { target: HTMLInputElement }) {
    this.customerInfo
      .get('requiredCustomerFeedbackAction')
      ?.patchValue(target.checked);
  }
  constructor(
    private caseService: CaseService,
    private router: Router,
    private datePipe: DatePipe
  ) {}
}
