<cm-menu></cm-menu>
<div class="login">
  <div class="login__shadow">
    <div class="stripes-container">
      <div class="stripe"></div>
      <div class="stripe"></div>
      <div class="stripe"></div>
      <div class="stripe"></div>
      <div class="stripe"></div>
      <div class="stripe"></div>
    </div>
    <div class="login__card">
      <storybook-label
        class="login__header"
        [content]="'Log in'"
        [type]="labelType.H1"></storybook-label>
      <p>
        <storybook-label
          [content]="'Welcome to Case Management.'"
          [type]="labelType.Main1">
        </storybook-label>
        <storybook-label
          [content]="
            'Please log in with your VR User Account to access this portal.'
          "
          [type]="labelType.Main1">
        </storybook-label>
      </p>
      <br />
      <storybook-button
        label="Login with SSO"
        (click)="login()"></storybook-button>
    </div>
  </div>
</div>
