<div class="header-container" #headerContainer>
  <a href="/">
    <img class="logo-img" src="/assets/icons/vr_logo.svg" />
  </a>
  <div class="nav-container">
    <div class="navigation-items" #navigationItemsContainer>
      Case Management
    </div>
  </div>
  <div *ngIf="userModel.name" class="data-container" #dataContainer>
    <div
      class="data-label-container pointer"
      #userInfoBox
      (click)="openUserInfo()">
      <storybook-icon
        class="user-icon"
        [color]="'var(--green05-green05)'"
        [path]="'/assets/icons/ico_user.svg'"
        [size]="'24px'"></storybook-icon>
      {{ userModel.name }}
      <storybook-icon
        [color]="'var(--grey20-white)'"
        [path]="'/assets/icons/ico_chevron.svg'"
        [size]="'24px'"></storybook-icon>
    </div>
  </div>
</div>
<div
  *ngIf="userInfoOpened"
  class="user-info-container user-info"
  #userInfoSelector>
  <storybook-button-v2
    class="logout-button"
    [label]="'Log out'"
    (onClick)="logoutClicked()"></storybook-button-v2>
</div>
