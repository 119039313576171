import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { AuthService } from '@shared/services/auth/auth.service';
import { UserInfoModel } from '@vr/vr-storybook-lib';

@Component({
  selector: 'cm-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  public userModel: UserInfoModel = { name: '', logoutLabel: 'Log out' };
  @ViewChild('userInfoBox') userInfoBoxRef: ElementRef | undefined;

  public userInfoOpened = false;

  async ngOnInit(): Promise<void> {
    this.authService.GetCurrentUser().then(x => {
      this.userModel.name = x.name;
    });
  }

  public openUserInfo() {
    this.userInfoOpened = !this.userInfoOpened;
  }

  public logoutClicked() {
    this.authService.logout();
  }

  constructor(
    public authService: AuthService,
    private renderer: Renderer2
  ) {
    this.renderer.listen('window', 'click', (e: Event) => {
      if (this.userInfoBoxRef) {
        const target = e.target as HTMLElement;
        if (!this.userInfoBoxRef.nativeElement.contains(target)) {
          this.userInfoOpened = false;
        }
      }
    });
  }
}
