import { Component } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'cm-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent {
  spaVersionId = environment.spaVersionId;
}
