import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PermissionsService } from '@shared/services/auth/permissions.service';
import { NotFoundComponent } from './not-found/not-found.component';
import { LayoutComponent } from './shared/components/layout/layout.component';
import { LoginComponent } from 'src/app/login/login.component';
import { CreateCaseComponent } from './create-case/create-case.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      { path: '', redirectTo: '/cases', pathMatch: 'full' },
      {
        path: 'cases',
        loadChildren: () =>
          import('./cases/cases.module').then(m => m.CasesModule),
        canActivate: [PermissionsService],
      },
      {
        path: 'case',
        loadChildren: () =>
          import('./case/case.module').then(m => m.CaseModule),
        canActivate: [PermissionsService],
      },
      {
        path: 'create-case',
        component: CreateCaseComponent,
      },
    ],
  },
  {
    path: 'logged-out',
    component: LoginComponent,
  },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
